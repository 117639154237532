import React, { useRef } from "react"
import { CardTimeline, InnerTimeline, ListTimeline, WrapperButton, WrapperTimeline } from "./timeline.style"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"

const Timeline = ({ history_items = [] }) => {
    const ref = useRef(null)

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset
    }

    return (
        <>
            <WrapperButton>
                <button onClick={() => scroll(-150)} className="button-icon button-icon--secondary button-icon--lg">
                    <i className="icon-arrow-left"></i>
                </button>
                <WrapperTimeline style={{ overflowX: "scroll" }} ref={ref}>
                    <InnerTimeline>
                        <ListTimeline>
                            {history_items.map((history, index) => (
                                <li key={index}>
                                    <CardTimeline>
                                        <h2 className="c-purple line-description">{history.title}</h2>
                                        <ReactMarkdown children={history.description} rehypePlugins={[rehypeRaw]} />
                                    </CardTimeline>
                                    <div className="divider">
                                        <div className="item-1" />
                                        <div className="item-2" />
                                        <div className="item-2" />
                                        <div className="item-2" />
                                        <div className="item-2" />
                                    </div>
                                </li>
                            ))}
                        </ListTimeline>
                    </InnerTimeline>
                </WrapperTimeline>
                <button onClick={() => scroll(150)} className="button-icon button-icon--secondary button-icon--lg">
                    <i className="icon-arrow-right"></i>
                </button>
            </WrapperButton>
        </>
    )
}

export default Timeline
