import styled from "styled-components";
import color from '../../../styles/colors.json';

export const WrapperTimeline = styled.div`
    width: 100%;
    height: 36em;
    overflow: auto;
    display: flex;
    align-items: center;
    padding-top: 0.5em;
    margin: 0 10px;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const InnerTimeline = styled.div`
    padding: 1em;
`

export const ListTimeline = styled.ul`
    display: flex;
    width: 100%;
    list-style: none;
    li:nth-child(odd){
        margin-top: -8em;
        .divider{
            width: 300px;
            height: 2em;
            margin-top: 1.5em;
            z-index: 100;
            display: flex;
            align-items: center;
            .item-1{
                width: 4px;
                height: 2em;
                margin-right: 57px;
                margin-left: 0;
                background-color: #FF8066;
            }
            .item-2{
                width: 4px;
                height: 1em;
                margin-right: 57px;
                margin-left: 0;
                background-color: #C9C5D1;
            }
        }
    }
    li:nth-child(even){
        margin-top: 8em;
        padding-bottom: 3em;
        .divider{
            width: 300px;
            height: 2em;
            margin-top: -14.5em;
            z-index: 100;
            display: flex;
            align-items: center;
            .item-1{
                width: 4px;
                height: 2em;
                margin-right: 57px;
                margin-left: 0;
                background-color: #FF8066;
            }
            .item-2{
                width: 4px;
                height: 1em;
                margin-right: 57px;
                margin-left: 0;
                background-color: #C9C5D1;
            }
        }
    }
`;


export const CardTimeline = styled.div`
    height: 200px;
    width: 300px;
    padding: 32px;
    background: #F8F8FA;
    border-radius: 16px;
    h2{
        color: #7756db;
        margin-bottom: 8px;
    }
    p{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;


export const WrapperButton  = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    width:100%;
    button{
        margin-top: 15px;
        background:${color.colorpurple};
        color: ${color.colorwhite};
        border: none;
        &:hover, :active, :focus {
            background:${color.colorpurple};
            color: ${color.colorwhite};
        }
    }
`;

